<template>
  <div>
    <b-card>
      <h5>이미지 설명</h5>
      <ul>
        <li>org: 원본 이미지</li>
        <li>org_webp: webp 포맷 으로 변경한 원본 이미지(화질 유지, 용량 감소)</li>
        <li>jpg: 630 x 840 사이즈(3:4)의 jpg 로 변환한 이미지(비율에 맞게 여백제거 or 백그라운드 색 인식 및 확장)</li>
        <li>webp: 630 x 840 사이즈의 webp 로 변환한 이미지</li>
        <li>thumb_300: 300 x 400 사이즈의 webp 로 변환한 썸네일(발란몰의 150 ~ 300 width 의 위치에서 사용)</li>
        <li>thumb_200: 200 x 267 사이즈의 webp 로 변환한 썸네일(발란몰의 100 ~ 150 width 의 위치에서 사용)</li>
        <li>square_webp: 640 x 640 사이즈의 webp 로 변환한 이미지</li>
        <li>square_jpg: 640 x 640 사이즈의 jpg 로 변환한 이미지(SmartStore, Naver EP 에 사용)</li>
        <li>square_200: 200 x 200 사이즈의 webp 로 변환한 이미지</li>
      </ul>
    </b-card>
    <b-alert variant="danger" :show="!!warn">{{warn}}</b-alert>
    <div v-for="img in images" class="border-bottom">
      원본 URL: <a :href="img.url" target="_blank">{{img.url}}</a><br/>
      <div v-for="i in img.keys" class="d-inline-block" style="vertical-align:top;">
        <span>{{i.key}} <span v-if="i.dim">({{i.dim.width}} x {{i.dim.height}}{{i.size ? ', ' + Math.round(i.size/100)/10 + ' KB' : ''}})</span></span><br/>
        <a :href="i.value" target="_blank"><img :src="i.value" style="max-width:210px;border:1px solid #999" /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MPImageCheck',
  title: 'MP 이미지 체크',
  data() {
    return {
      warn: '',
      images: [],
    }
  },
  async created() {
    const gid = this.$route.params.gid;
    if (gid) {
      this.load(gid);
    }
  },
  methods: {
    async load(gid) {
      let j = await this.$api.getJson('/goods/mpImage?gid=' + encodeURIComponent(gid));
      if (j) {
        if (!j.mp.images) {
          this.warn = '이미지 처리가 되기 전의 상품입니다.'
          this.images = j.mp.img_urls.map((e, i) => ({url: e, keys: [{key: `img ${i}`, value: e}]}));
          return;
        }
        this.warn = '';
        this.images = j.mp.images.map(e=>{
          let keys = 'org,org_webp,jpg,webp,thumb_300,thumb_200,square_webp,square_jpg,square_200'.split(',');
          return {url: e.url, keys: keys.filter(k => e[k]).map(k => ({key: k, value: `https://i.balaan.io/${j.mp.image_path}${e[k]}`}))};
        });
        this.images.forEach(e => {
          e.keys.forEach(i => this.getImgSize(i));
        });
      }
    },
    getImgSize(img) {
      let newImg = new Image();

      newImg.onload = () => {
        const height = newImg.height;
        const width = newImg.width;
        img.dim = {height, width};
        this.$forceUpdate();
      }

      newImg.src = img.value; // this must be done AFTER setting onload
      fetch(img.value).then(r => r.blob()).then(b => { img.size = b.size; this.$forceUpdate(); });
    }
  }
}
</script>
